import React from 'react'
import { ExploreCoursesTabs } from '../../json/home/ExploreCoursesTabs'
import { Link } from 'react-router-dom'
import { EntranceExamsTabs } from '../../json/home/EntranceExamsTabs'

export const ExploreCourses = () => {
    return (
        <div className='bg-[#F7F7F7] py-10'>
            <div className='text-center'>
                <h2 className='text-center font-bold text-3xl border-b-4 border-red-600 inline '>Explore Courses</h2>
            </div>
            <div className='flex flex-wrap items-center justify-center lg:gap-6 gap-4 mt-10 px-10'>
                {ExploreCoursesTabs?.map(item=>(
                    <Link key={item?.Name} to={item?.Url} className='px-4 py-1 rounded-full bg-white shadow flex items-center'><span className='mx-2'>{item?.Icon}</span>{item?.Name}</Link>
                ))}
            </div>
        </div>
    )
}

export const EntranceExams = () => {
    return (
        <div className='bg-[#F7F7F7] py-10 '>
        
            <div className='text-center  '>
                <h2 className='text-center font-bold text-3xl border-b-4 border-red-600 inline   p-2'>Entrance Exams</h2>
            </div>
            <div className='flex flex-wrap items-center justify-center lg:gap-6 gap-4 mt-10 px-10'>
                {EntranceExamsTabs?.map((item,index)=>(
                    <Link key={index} to={item?.Url} className='px-4 py-1 rounded-full bg-white shadow flex items-center'><span className='mx-2'>{item?.Icon}</span>{item?.Name}</Link>
                ))}
            </div>
        </div>
    )
}

