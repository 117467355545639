import React, {  useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchAllReviewsQuery } from '../../store/slice/CollegeSlice';
import StarRating from './StarRating';
import { FaStar, FaThumbsUp, FaThumbsDown} from 'react-icons/fa';
import { useSelector } from 'react-redux';

const CollegeReview = () => {

    const { collegeId } = useParams();
    const { data: response, isLoading, isError } = useFetchAllReviewsQuery(collegeId);
    const reviews = response?.reviews || [];
    const user = useSelector((state) => state.auth.user);
    {console.log(response)}

    // State for like/dislike
    const [likes, setLikes] = useState({});
    const [dislikes, setDislikes] = useState({});

    // Filtered reviews based on user selection
    const filteredReviews =  reviews;

    // Calculate average ratings
    const averageRating = (key) => {
        if (reviews.length === 0) return 0; 
        const total = reviews.reduce((acc, review) => acc + (review[key] || 0), 0); 
        return (total / reviews.length).toFixed(1);
    };
     
    if (isLoading) return <div className="text-center py-12 text-gray-500">Loading...</div>;
    if (isError) return <div className="text-center py-12 text-red-500 text-3xl">No Reviews Found</div>;

  
    // Handle like/dislike clicks
    const handleLike = (reviewId) => {
        setLikes({ ...likes, [reviewId]: (likes[reviewId] || 0) + 1 });
    };

    const handleDislike = (reviewId) => {
        setDislikes({ ...dislikes, [reviewId]: (dislikes[reviewId] || 0) + 1 });
    };


    return (
        <div className="p-6 max-w-6xl mx-auto">
            <h2 className="text-4xl font-extrabold mb-8 text-center text-gray-800">Reviews for College </h2>
            
          
            {/* Average Ratings Section */}
            <div className="mb-10">
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 text-center">
                
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">Faculty Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('Facultyrating')}</span>
                        </div>
                    </div>
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">Placement Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('Placementrating')}</span>
                        </div>
                    </div>
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">Campus Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('Campusrating')}</span>
                        </div>
                    </div>
                    <div className="p-4 bg-blue-100 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700">Academic Rating</h3>
                        <div className="flex items-center justify-center mt-2">
                            <FaStar className="text-yellow-500 mr-2" />
                            <span className="text-2xl font-bold">{averageRating('StudentLiferating')}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Reviews List */}
            {filteredReviews.length > 0 ? (
  <ul className="space-y-4 grid grid-cols-3 gap-4">
    {filteredReviews.map((review) => (
      <li 
        key={review._id} 
        className="p-4 border rounded-md shadow-md bg-white transition duration-300 hover:shadow-lg hover:bg-blue-50"
      >
        <div className="flex flex-col items-center mb-3">
       
          <div className="flex items-center justify-between w-full mb-2">
    <span className="text-gray-600 text-sm">Review By:</span>
    <h3 className="text-lg font-bold text-gray-800">{user?.fullName}</h3>
  </div>
          <div className="flex items-center space-x-2">
            <div className="flex items-center">
            
              <span className="text-base font-medium">{review.generalRating}</span>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex justify-between items-center text-xs">
            <span className="font-medium w-36 text-gray-600">Faculty Rating:</span>
            <StarRating rating={review.Facultyrating} />
          </div>
          <div className="flex justify-between items-center text-xs">
            <span className="font-medium w-36 text-gray-600">Placement Rating:</span>
            <StarRating rating={review.Placementrating} />
          </div>
          <div className="flex justify-between items-center text-xs">
            <span className="font-medium w-36 text-gray-600">Campus Rating:</span>
            <StarRating rating={review.Campusrating} />
          </div>
          <div className="flex justify-between items-center text-xs">
            <span className="font-medium w-36 text-gray-600">Student Life Rating:</span>
            <StarRating rating={review.StudentLiferating} />
          </div>
        </div>
      
      
        <div className="flex justify-center space-x-6 mt-3">
          <button
            onClick={() => handleLike(review._id)}
            className="flex items-center text-green-500 hover:text-green-600 transition"
            aria-label="Like Review"
          >
            <FaThumbsUp className="mr-1 text-lg" />
            <span className="text-xs">{likes[review._id] || 0}</span>
          </button>
          <button
            onClick={() => handleDislike(review._id)}
            className="flex items-center text-red-500 hover:text-red-600 transition"
            aria-label="Dislike Review"
          >
            <FaThumbsDown className="mr-1 text-lg" />
            <span className="text-xs">{dislikes[review._id] || 0}</span>
          </button>
        </div>

        {/* <div className="mt-3 text-center text-gray-500 text-xs">
          <span>Was this review helpful?</span>
          <div className="flex justify-center space-x-3 mt-1">
            <button
              className="text-blue-500 hover:text-blue-600 transition font-medium"
              aria-label="Mark as Helpful"
            >
              Yes
            </button>
            <button
              className="text-blue-500 hover:text-blue-600 transition font-medium"
              aria-label="Mark as Not Helpful"
            >
              No
            </button>
          </div>
        </div> */}
      </li>
    ))}
  
  </ul>
) : (
  <p className="text-center text-xs text-gray-500">No reviews available.</p>
)}




        </div>
    );
};

export default CollegeReview;
