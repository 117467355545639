import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { PrimaryMenu } from "../../json/Menus";
import { useNavigate } from "react-router-dom";

const MobileMenu = ({ setSidebar, sidebar }) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [subDropdownOpen, setSubDropdownOpen] = useState(null); // Handle nested submenu
  const [subItemsToShow, setSubItemsToShow] = useState({});
  const timeoutRef = useRef(null);

  const handleClick = (url) => {
    navigate(url);
    setSidebar(false);
  };

  const handleMenuToggle = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleSubmenuToggle = (subIndex) => {
    setSubDropdownOpen(subDropdownOpen === subIndex ? null : subIndex);
  };

  const loadMoreSubItems = (menuIndex) => {
    setSubItemsToShow((prev) => ({
      ...prev,
      [menuIndex]: (prev[menuIndex] || 5) + 5,
    }));
  };

  return (
    <div>
      {/* Sidebar background overlay */}
      <div
        className={`${
          !sidebar && "hidden"
        } fixed inset-0 bg-black opacity-50 z-40`}
        onClick={() => setSidebar(false)}
      />

      {/* Sidebar container */}
      <div
        className={`lg:hidden z-50 fixed top-0 bottom-0 left-0 w-3/4 sm:w-2/3 bg-gray-800 text-white transform ${
          sidebar ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out overflow-y-auto pt-6`}
      >
        {/* Close button */}
        <div className="flex justify-end px-4">
          <span onClick={() => setSidebar(false)} className="cursor-pointer">
            <ImCross className="text-white text-lg" />
          </span>
        </div>

        {/* Menu items */}
        <div className="mt-6">
          {PrimaryMenu?.map((item, index) => (
            <div key={item?.Name} className="group relative">
              {/* Main menu item */}
              <div
                onClick={() => handleMenuToggle(index)}
                className="px-4 py-2 text-lg font-semibold cursor-pointer hover:bg-gray-800 flex justify-between items-center"
              >
               <Link to={item.Url} onClick={() => handleClick(item.Url)}>
                  {item.Name}
                </Link>
                {item?.SubMenu && (
                  <span className="ml-auto">
                    {dropdownOpen === index ? "-" : "+"}
                  </span>
                )}
              </div>

              {/* Dropdown menu */}
              {item?.SubMenu && dropdownOpen === index && (
                <div className="pl-6 pr-4 py-2 bg-gray-800 rounded-md">
                  <ul>
                    {item?.SubMenu.slice(0, subItemsToShow[index] || 5).map(
                      (subItem, subIndex) => (
                        <li key={subItem?.Name} className="relative">
                          <div
                            onClick={() => handleSubmenuToggle(subIndex)}
                            className="flex justify-between items-center py-1 text-sm text-gray-300 bg-gray-800 hover:text-white transition-all cursor-pointer"
                          >
                            <Link
                              to={subItem?.Url}
                              className="block"
                              onClick={() => setSidebar(false)}
                            >

                                
                              {subItem?.Name}
                            </Link>
                            {subItem?.SubMenu && (
                              <span>
                                {subDropdownOpen === subIndex ? "-" : "+"}
                              </span>
                            )}
                          </div>

                          {/* Nested submenu */}
                          {subItem?.SubMenu && subDropdownOpen === subIndex && (
                            <ul className="pl-4 bg-gray-800">
                              {subItem?.SubMenu.map((nestedItem) => (
                                <li key={nestedItem?.Name}>
                                  <Link
                                    to={nestedItem?.Url}
                                    className="block py-1 text-sm text-gray-300 hover:bg-gray-500 hover:text-white transition-all"
                                    onClick={() => setSidebar(false)}
                                  >
                                    {nestedItem?.Name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      )
                    )}

                    {item?.SubMenu.length > 5 && (
                      <button
                        onClick={() => loadMoreSubItems(index)}
                        className="block mt-2 text-blue-400 hover:underline"
                      >
                        Read More
                      </button>
                    )}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
