import React from 'react'
import { useParams } from 'react-router-dom'
import { useFetchArticlesBySlugQuery } from '../store/slice/ArticleSlice';
import { AiFillThunderbolt } from 'react-icons/ai';

const storage = process.env.REACT_APP_S3_BUCKET;

const Article = () => {
    window.scroll(0,0);
    const { slug } = useParams();
    const { data } = useFetchArticlesBySlugQuery(slug);
    const Article = data?.data[0];
    const ArticleImage = storage + Article?.thumbnail;

    return (
        <div>
            <div style={{ backgroundImage: `url(${ArticleImage})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className='min-h-[400px]'>
            </div>
            <div className='max-w-6xl -mt-10 m-auto bg-white'>
                <div className='flex shadow-lg'>
                    <img src={storage + Article?.logo} alt="" srcset="" />
                    <h1 className='text-3xl font-bold px-4 py-2'><AiFillThunderbolt className='inline text-red-700' />{Article?.title}</h1>
                </div>
            </div>
            <div className='max-w-6xl m-auto mb-10'>
                {/* <div className='border border-gray-300 mt-10 rounded'>
                    <p className='p-4'>{Article?.shortDesc}</p>
                </div> */}
                <div className='articleContent border border-gray-300 rounded mt-10 p-6' dangerouslySetInnerHTML={{__html:Article?.content}}>
                </div>
            </div>

        </div>
    )
}

export default Article
