import React from 'react';

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('https://t3.ftcdn.net/jpg/04/28/08/38/240_F_428083893_HZ7mGYJPaohTnU73H3FOAPVR4FlsqHby.jpg')" }}>
      <div className="bg-black bg-opacity-60 p-8 rounded-lg shadow-lg text-center">
        <h2 className="text-5xl text-white font-bold mb-4">Coming Soon</h2>
        <p className="text-xl text-gray-300">We're working hard to bring you something amazing. Stay tuned!</p>
      </div>
    </div>
  );
}

export default ComingSoon;
