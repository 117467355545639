import React, { useState, useEffect } from "react";

import {
  useFetchAllForSearchQuery,
  useFetchAllCoursesQuery,
  useFetchCollegesByLocationQuery,
} from "../../store/slice/CollegeSlice";
import { motion } from "framer-motion";
import { Link, useLocation, useParams } from "react-router-dom";
import { Country, State } from "country-state-city";

const storage = process.env.REACT_APP_S3_BUCKET;

const CollegeCompare = () => {
  const { country } = useParams();
  const { state } = useParams();
  const location = useLocation();
  const { preSelectedCollege } = location.state || {};
  const [selectedColleges, setSelectedColleges] = useState([null, null,null,null]);
  const [searchQueries, setSearchQueries] = useState(["", "", "", ""]);
  const [collegeCourses, setCollegeCourses] = useState([[], [], [], []]);
  const [selectedCourses, setSelectedCourses] = useState([null,null,null,null]);
  const { data: CollegeLocation2 } = useFetchCollegesByLocationQuery({country, state,});
  const LocationCollege = CollegeLocation2?.CollegeByLocation;


  useEffect(() => {
    if (preSelectedCollege) {
      setSelectedColleges((prev) => {
        const newSelection = [...prev];
        newSelection[0] = preSelectedCollege;
        return newSelection;
      });
      setSearchQueries((prev) => {
        const newQueries = [...prev];
        newQueries[0] = preSelectedCollege.name;
        return newQueries;
      });
    }
  }, [preSelectedCollege]);
  // Fetch search results for each search query
  const { data: searchResults1 = [] } = useFetchAllForSearchQuery( searchQueries[0], { skip: !searchQueries[0] });
  const { data: searchResults2 = [] } = useFetchAllForSearchQuery(  searchQueries[1],{ skip: !searchQueries[1] });
  const { data: searchResults3 = [] } = useFetchAllForSearchQuery( searchQueries[2], { skip: !searchQueries[2] });
  const { data: searchResults4 = [] } = useFetchAllForSearchQuery(searchQueries[3],{ skip: !searchQueries[3]});
  const searchResults = [searchResults1, searchResults2,searchResults3, searchResults4, ];
  // Fetch courses for each selected college
  const coursesQuery1 = useFetchAllCoursesQuery(selectedColleges[0]?._id, {skip: !selectedColleges[0]?._id,});
  const coursesQuery2 = useFetchAllCoursesQuery(selectedColleges[1]?._id, {skip: !selectedColleges[1]?._id,});
  const coursesQuery3 = useFetchAllCoursesQuery(selectedColleges[2]?._id, {skip: !selectedColleges[2]?._id, });
  const coursesQuery4 = useFetchAllCoursesQuery(selectedColleges[3]?._id, {skip: !selectedColleges[3]?._id,});
    
  

  useEffect(() => {
    const updatedCourses = [
      coursesQuery1.data?.Course || [],
      coursesQuery2.data?.Course || [],
      coursesQuery3.data?.Course || [],
      coursesQuery4.data?.Course || [],
    ];
    setCollegeCourses(updatedCourses);
  }, [
    coursesQuery1.data,
    coursesQuery2.data,
    coursesQuery3.data,
    coursesQuery4.data,
    selectedColleges,
  ]);

  const handleSelectCollege = (index, college) => {
    const newSelection = [...selectedColleges];
    newSelection[index] = college;
    setSelectedColleges(newSelection);
    setSearchQueries((prevQueries) => {
      const newQueries = [...prevQueries];
      if (prevQueries[index] !== college.name) {
        newQueries[index] = college.name;
      }
      return newQueries;
    });
  };

  const handleSearchChange = (index, value) => {
    const newQueries = [...searchQueries];
    newQueries[index] = value;
    setSearchQueries(newQueries);
  };

  // Handle course selection
  const handleSelectCourse = (index, courseId) => {
    const updatedSelectedCourses = [...selectedCourses];
    updatedSelectedCourses[index] = courseId;
    setSelectedCourses(updatedSelectedCourses);
  };

  return (
    <div className="mx-auto w-full p-8 bg-gradient-to-r from-blue-50 to-teal-50">
    {/* Title Section */}
    <div className="text-center mb-12">
      <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
        Select Colleges for Comparison
      </h2>
      <p className="text-lg text-gray-700 italic">
        "Education is the most powerful weapon which you can use to change the world." - Nelson Mandela
      </p>
    </div>

{/* Suggestions Section */}
{LocationCollege && Array.isArray(LocationCollege) && LocationCollege.length > 0 && (
  <div className="p-4 rounded-md shadow-md bg-white mb-8">
    <h3 className="text-lg font-semibold mb-2 text-gray-700">Suggestions:</h3>
    <div className="flex flex-wrap gap-2">
      {LocationCollege.map((college, index) => (
        <Link
          to={`/college/${college.slug}`}
          key={index}
          className="bg-gray-100 text-green-800 border border-green-300 rounded-full px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-300 hover:text-white transition-colors cursor-pointer"
        >
          {college?.name}
        </Link>
      ))}
    </div>
  </div>
)}


   
 
    {/* College Search & Selection Section */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
      {selectedColleges?.map((college, index) => (
        <motion.div
          key={index}
          className="relative border rounded-xl shadow-lg p-6 flex flex-col items-center bg-white"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-xl font-bold mb-4 text-gray-900">Search College {index + 1}</h3>
          <input
            type="text"
            placeholder="Search College..."
            value={searchQueries[index]}
            onChange={(e) => handleSearchChange(index, e.target.value)}
            className="mb-2 p-2 border border-gray-300 rounded-full w-full bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
  
          {/* Dynamic Search Results */}
          {searchQueries[index] && (
            <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg max-h-40 overflow-y-auto z-10 shadow-lg mt-2">
              {searchResults[index].map((filteredCollege) => (
                <li
                  key={filteredCollege._id}
                  onClick={() => handleSelectCollege(index, filteredCollege)}
                  className="p-2 cursor-pointer hover:bg-blue-500 hover:text-white transition-colors"
                >
                  {filteredCollege.name}
                </li>
              ))}
            </ul>
          )}
  
          {/* Course Selection Dropdown */}
          {selectedColleges[index] && (
            <div className="mt-4 w-full">
              <label className="block text-gray-700 font-bold mb-2">Select Course:</label>
              <select
                className="w-full border border-gray-300 p-2 rounded-md"
                value={selectedCourses[index] || ""}
                onChange={(e) => handleSelectCourse(index, e.target.value)}
              >
                <option value="" disabled>Select a course</option>
                {collegeCourses[index]?.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course.courseName}
                  </option>
                ))}
              </select>
            </div>
          )}
        </motion.div>
      ))}
    </div>
  
    {/* College Comparison Section */}
    <div className="mt-12">
      <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-900">College Comparison</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {selectedColleges?.map((college, index) => {
          if (!college) return null;
  
          const colors = ["blue", "green", "yellow", "red"];
          const color = colors[index % colors.length];
  
          const selectedCourse = collegeCourses[index]?.find(
            (course) => course._id === selectedCourses[index]
          );
  
          return (
            <motion.div
              key={college._id}
              className={`relative border rounded-xl shadow-lg p-6 bg-${color}-50 border-${color}-400 transition-transform duration-500 ease-in-out hover:scale-105 hover:shadow-2xl`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              {/* College Cover Image */}
              <div className="mb-4">
                {college.coverimage && (
                  <img
                    src={`${storage}${college.coverimage}`}
                    alt={college.name}
                    className="w-full h-40 object-cover rounded-lg shadow-md transition-transform duration-500 hover:scale-105"
                  />
                )}
              </div>
  
              {/* College Name and Details */}
              <h3 className="text-xl font-bold text-gray-800 mb-4">{college.name}</h3>
  
              <div className="space-y-3">
                <div className="flex items-center border-b border-gray-300 py-2 last:border-b-0">
                  <span className="text-gray-600">{college.subtitle}</span>
                </div>
                <div className="flex items-center border-b border-gray-300 py-2 last:border-b-0">
                  <span className="font-medium text-gray-700">Rating: {college.rating}</span>
                </div>
                <div className="flex items-center border-b border-gray-300 py-2 last:border-b-0">
                  <span className="font-medium text-gray-700">
                    Location:{" "}
                    {typeof college.location[0] === "string" ? (
                      college.location[0]
                    ) : (
                      `${State.getStateByCodeAndCountry(college.location[0].state, college.location[0].country)?.name}, ${Country.getCountryByCode(college.location[0].country)?.name}`
                    )}
                  </span>
                </div>
  
                {/* Selected Course Details */}
                {selectedCourse && (
                  <div className="mt-4 rounded-lg">
                    <h4 className="text-lg underline font-semibold text-gray-800 mb-4">Selected Course</h4>
                    <div className="space-y-6">
                      <div className="flex items-center">
                        <span className="font-medium text-sm text-gray-700 w-1/3">Course Name:</span>
                        <p className="text-gray-600 text-sm">{selectedCourse.courseName}</p>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium text-sm text-gray-700 w-1/3">Duration:</span>
                        <p className="text-gray-600 text-sm">{selectedCourse.duration}</p>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium text-sm text-gray-700 w-1/3">Fees:</span>
                        <p className="text-gray-600 text-sm">{selectedCourse.fees}</p>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium text-sm text-gray-700 w-1/3">Eligibility:</span>
                        <p className="text-gray-600 text-sm">{selectedCourse.eligibility}</p>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium text-sm text-gray-700 w-1/3">Mode Of Study:</span>
                        <p className="text-gray-600 text-sm">{selectedCourse.modeOfStudy}</p>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium text-sm text-gray-700 w-1/3">Cutoff:</span>
                        <p className="text-gray-600 text-sm">{selectedCourse.cutoff}</p>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium text-sm text-gray-700 w-1/3">Course Level:</span>
                        <p className="text-gray-600 text-sm">{selectedCourse.courseLevel}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  </div>
  
  );
};

export default CollegeCompare;
