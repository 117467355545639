import React from "react";
import { Link } from "react-router-dom";
import { TextConcat } from "../../utils/TextConcat";
import { Country, State } from "country-state-city";

const storage = process.env.REACT_APP_S3_BUCKET;

const AllColleges = ({ data }) => {

  return (
    <div>


      <div className="py-10 px-4 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2">
        {data?.length > 0 &&
          data?.map((item, index) => (
            <Link
              to={`/college/${item?.slug}`}
              key={index}
              className="m-2 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 bg-gray-50 rounded border border-gray-200 shadow"
            >
              <div>
                <img
                  src={storage + item?.coverimage}
                  alt=""
                  className="lg:w-[250px] w-full h-[170px] rounded"
                />
              </div>
              <div className="px-2 lg:col-span-2">
                <h2 className="font-bold text-lg">
                  {TextConcat(item?.name, 60)}
                </h2>
                <p className="flex gap-4 mt-2 text-sm">
                  <span className="bg-blue-600 rounded text-white px-4">
                    {" "}
                    {typeof item.location[0] === "string" ? (
                      <span className="bg-blue-600 rounded text-white px-4">
                        {TextConcat(item?.location[0], 10)}
                      </span>
                    ) : (
                      <span className="bg-blue-600 rounded text-white px-4">
                      
                        <span >
                        {State.getStateByCodeAndCountry(item?.location[0]?.state,item?.location[0]?.country)?.name + ', ' + Country.getCountryByCode(item?.location[0]?.country)?.name  }
                      </span>
                      </span>
                    )}
                  </span>
                  <span className="bg-red-700 rounded text-white px-4">
                    {TextConcat(item?.subtitle, 25)}
                  </span>
                </p>
                <div className="my-4 text-sm grid grid-cols-2 gap-4">
                  <Link
                    to={`/college/${item?.slug}`}
                    className="shadow px-4 py-1 -mt-2 rounded-full text-center bg-white "
                  >
                    College Info
                  </Link>
                  <Link
                    to={`/college/${item?.slug}`}
                    className="shadow px-4 py-1 -mt-2 rounded-full text-center bg-white "
                  >
                    Courses & Fee
                  </Link>
                  <Link
                    to={`/college/${item?.slug}`}
                    className="shadow px-4 py-1 -mt-2 rounded-full text-center bg-white "
                  >
                    Admission
                  </Link>
                  <Link
                    to={`/college/${item?.slug}`}
                    className="shadow px-4 py-1 -mt-2 rounded-full text-center bg-white "
                  >
                    Scholorship
                  </Link>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default AllColleges;
