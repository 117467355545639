import React from 'react'
import loadergif from '../assets/loadergif.gif';

const Loader = () => {
  return (
    <div className='flex items-center justify-center h-48'>
      <img src={loadergif} className='w-20' alt='loading...'/>
    </div>
  )
}

export default Loader