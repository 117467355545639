import React, { useEffect } from "react";
import { useFetchCourseBySlugQuery } from "../../store/slice/CollegeSlice";
import { Link, useParams } from "react-router-dom";

const CourseByCollege = () => {
  const { slug } = useParams();

  const { data: Courses } = useFetchCourseBySlugQuery(slug);
  console.log(Courses)


  return (
    <div className="mx-10">
      <h3 className="text-center text-3xl mb-5"> All Courses</h3>
      <div className="grid lg:grid-cols-3 grid-col-1 gap-4">
        {Courses?.Course?.length > 0 &&
          Courses?.Course.map((item, index) => (
            <div className="" key={index}>
              <div className="h-auto w-[250px] border-2 border-black ">
                <img
                  className="w-[250px] h-[200px]"
                  src="https://instructor-academy.onlinecoursehost.com/content/images/size/w2000/2023/05/How-to-Create-an-Online-Course-For-Free--Complete-Guide--6.jpg"
                />
                <h3 className="text-center font-semibold italic">
                  Name: {item.courseName}
                </h3>

                <h3 className="text-center font-semibold italic  pb-1">
                  Level: {item?.courseLevel}
                </h3>
                {/* <h3 className="text-center font-semibold italic  pb-1">
              Duration: {item?.duration} years
            </h3>
            <h3 className="text-center font-semibold italic  pb-1">
              Eligibilty: {item?.eligibility}
            </h3>
            <h3 className="text-center font-semibold italic  pb-1">
              Ranking:{item?.ranking}
            </h3>
            <h3 className="text-center font-semibold italic  pb-1">
              Mode of Study:{item?.modeOfStudy}
            </h3> */}
                <div className="flex justify items-center mb-2  ">
           
                  <Link
                    to={`/course-view/${item?._id}`}
                    className="mx-auto bg-blue-600 p-2 rounded-lg  text-white "
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseByCollege;
