import Article from "../pages/Article";
import Home from "../pages/Home";
import ArticlesByCategory from "../pages/ArticlesByCategory";
import Colleges from "../pages/Colleges";
import CollegeView from "../pages/CollegeView";
import UgPgComponent from "../components/article/UgPgComponent";
import ComingSoon from "../pages/ComingSoon";
import CreateReview from "../components/review/CreateReview";
import CollegeReview from "../components/review/CollegeReview";
import CollegeCompare from "../components/college/CollegeCompare";
import Login from "../components/login/Login";
import Register from "../components/login/Register";
import AllCourses from "../components/college/AllCourses";
import CourseByCollege from "../components/college/CourseByCollege";
import CourseView from "../components/college/CourseView";
import SpecializationsByCourse from "../components/college/SpecializationsByCourse";
import SpecializationView from "../components/college/SpecializationView";


export const MainRoute = [
    {
        
        Name:'login',
        Component:Login,
        Url:'/login'
    },
    {
        Name:'register',
        Component:Register,
        Url:'/register'
    },
    {
        Name: 'Home',
        Component: Home,
        Url:'/'
    },
    {
        Name: 'Article',
        Component: Article,
        Url:'/article/:slug'
    },
    {
        Name: 'Article',
        Component: ArticlesByCategory,
        Url:'/article/category/:id'
    },
    {
        Name: 'College',
        Component: Colleges,
        Url:'/allcolleges/'
    },
    {
        Name: 'College',
        Component: CollegeView,
        Url:'/college/:slug'
    },
    {
        Name: 'UgPgComponent',
        Component:UgPgComponent,
        Url:'/article/colleges/courses/:name/:id'
    },
    {
        Name:'Coming',
        Component:ComingSoon,
        Url:'/comingSoon'
    },
    {
        Name:'AllCourses',
        Component:AllCourses,
        Url:'/courses'
    },
    {
        Name:'CourseByCollege',
        Component:CourseByCollege,
        Url:'/coursebyCollege'
    },
    {
        Name:'CourseView',
        Component:CourseView,
        Url:'/course-view/:id'
    },
    {
        Name:'SpecializationByCourse',
        Component:SpecializationsByCourse,
        Url:'/specializationByCourse'
    },
    {
        Name:'SpecializationView',
        Component:SpecializationView,
        Url:'/specialization-view/:id'
    },
    {
        Name:'CollegeReview',
        Component:CollegeReview,
        Url:'/review/college/:collegeId'
    },
    {
        Name:'CreateReview',
        Component:CreateReview,
        Url:'/createReview'
    },
    {
        Name:'CompareCollege',
        Component:CollegeCompare,
        Url:'/compare/college/:country/:state'
    },


]