import { FaMoneyBill, FaUniversity, FaUserNurse } from "react-icons/fa";
import { FaComputer, FaUserDoctor } from "react-icons/fa6";
import { GoLaw } from "react-icons/go";
import { IoBookSharp } from "react-icons/io5";

export const ExploreCoursesTabs = [
    {
        Name:'B.Tech',
        Url:'/article/unraveling-the-world-of-b-tech-b-e-a-comprehensive-guide',
        Icon:<FaUniversity />
    },
    {
        Name:'MBA',
        Url:'/article/top-mba-colleges-in-india-2023-rankings-fees-admissions-courses-placements',
        Icon:<FaMoneyBill />
    },
    {
        Name:'ANM',
        Url:'/article/result-of-the-aiims-bsc-nursing-entrance-exam-in-2024-rank-rundown-delivered-check-cutoffaiims',
        Icon:<FaUserNurse/>
    },
    {
        Name:'BA',
        Url:'/article/bachelor-of-arts-b-a',
        Icon:<IoBookSharp/>
    },
    {
        Name:'MBBS',
        Url:'/article/navigating-the-world-of-mbbs-top-colleges-careers-and-faqs',
        Icon:<FaUserDoctor />
    },
    {
        Name:'M.Tech',
        Url:'/article/mtech',
        Icon:<FaComputer/>
    },
    {
        Name:'BSC',
        Url:'/',
        Icon:<FaUniversity />
    },
    {
        Name:'MD',
        Url:'/article/popular-courses-in-medical-education',
        Icon:<FaUserDoctor />
    },
    {
        Name:'PHD',
        Url:'/',
        Icon:<FaUniversity />
    },
    {
        Name:'B.Pharma',
        Url:'/article/popular-courses-in-medical-education',
        Icon:<FaUserDoctor />
    },
    {
        Name:'D.Pharma',
        Url:'/article/popular-courses-in-medical-education',
        Icon:<FaUserDoctor />   
    },
    {
        Name:'BBA',
        Url:'/',
        Icon:<FaUniversity />
    },
    {
        Name:'LLB',
        Url:'/article/unveiling-the-world-of-ba-llb-top-colleges-careers-and-faqs-for-aspiring-legal-minds',
        Icon:<GoLaw/>
    },
    {
        Name:'BCA',
        Url:'/article/navigating-the-world-of-bachelor-of-computer-applications-bca',
        Icon:<FaUniversity />
    }, {
        Name:'B.Com',
        Url:'/article/exploring-bachelor-of-commerce-b-com-and-future-career-paths',
        Icon:<FaUniversity />
    },

    {
        Name:'D.El.Ed',
        Url:'/',
        Icon:<FaUniversity />
    },
    {
        Name:'CA',
        Url:'/',
        Icon:<FaUniversity />
    },   
]