import React, { useState } from 'react';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import kid from "../../assets/kid.png"
import { Link } from 'react-router-dom';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  
  return (
    <div className="faq-container" style={{marginTop:"50px",marginBottom:"20px"}}>
      <h1 className="faq-header text-bold text-3xl"><b>Frequently Asked Questions</b></h1>
      
      <div className="faq-content">
        <div className="faq-image-container">
          <img src={kid} alt="FAQ" className="faq-image" />
        </div>
        <div className="faq-questions-container">
          <div className="faq-question">
            <div className="faq-question-header" onClick={() => handleToggle(1)}>
              <span>How do I search for Colleges and Exams?</span>
              {activeIndex === 1 ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </div>
            {activeIndex === 1 && (
              <div className="faq-answer">
                To search for colleges and exams on Shikshapedia, simply navigate to the website and use the search bar at the top of the page. Enter relevant keywords, such as the name of a college, exam, or specific course, and click the search icon or press "Enter". Review the search results to find a list of colleges, exams, or other relevant information.
              </div>
            )}
          </div>
          <div className="faq-question">
            <div className="faq-question-header" onClick={() => handleToggle(2)}>
              <span>How Shikshapedia can help me in my Career?</span>
              {activeIndex === 2 ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </div>
            {activeIndex === 2 && (
              <div className="faq-answer">
                Shikshapedia serves as an indispensable tool for career advancement, offering a wealth of information on colleges, courses, and exams. Its intuitive interface enables students to delve into educational opportunities, preparing them for future endeavors. By providing tailored recommendations and extensive research resources, Shikshapedia empowers individuals to make educated choices about their academic and professional trajectories.
              </div>
            )}
          </div>
          <div className="faq-question">
            <div className="faq-question-header" onClick={() => handleToggle(3)}>
              <span>Can I get support if I face issues with Shikshapedia?</span>
              {activeIndex === 3 ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </div>
            {activeIndex === 3 && (
              <div className="faq-answer">
                Yes, Shikshapedia provides support to its users. You can reach out to our support team via email or through the contact form on our website. We strive to resolve any issues or queries you might have promptly.
              </div>
            )}
          </div>
         
        </div>
      </div>
    
      
    </div>
  );
};

export default Faq;
