import React, { useEffect } from "react";
import { useFetchAllCoursesListQuery } from "../../store/slice/CollegeSlice";

const AllCourses = () => {
  const { data: courses } = useFetchAllCoursesListQuery();
 
  const storage = process.env.REACT_APP_S3_BUCKET;


  return (
    <div>
      <div className="max-w-6xl mx-auto">
        <h3 className="text-center text-3xl font-semibold underline">
          All Courses
        </h3>
        <div className="grid grid-cols-1 lg:grid grid-cols-4  h-auto gap-4">
          {courses?.AllCourses.length > 0 &&
            courses?.AllCourses.map((item) => (
              <div
                key={item._id}
                className=" items-center justify-center shadow-lg rounded-lg border-2 border-black"
              >
                {item?.collegeId?.coverimage ? (
                  <img src={storage + item?.collegeId?.coverimage} />
                ) : (
                  <img src="https://foundr.com/wp-content/uploads/2023/04/How-to-create-an-online-course.jpg.webp" />
                )}

                <h2 className="text-center font-semibold p-2">
                  {item?.courseName}
                </h2>
                <h2 className="text-center font-semibold p-2">
                  {item?.collegeId?.name}
                </h2>
                <div className="flex flex-col justify-center items-center space-y-4 mb-5 m-3">
                  <button className="p-2 w-full bg-blue-500 coursor-pointer rounded-lg text-white">
                    Read More
                  </button>
                  <button className="p-2 bg-red-500 w-full coursor-pointer rounded-lg text-white ">
                    View All Specializations
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
