import React from 'react';

const StarRating = ({ rating }) => {
    const stars = Array(5).fill(false).map((_, index) => index < rating);
    
    return (
        <div className="flex items-center">
            {stars.map((filled, index) => (
                <svg
                    key={index}
                    className={`w-6 h-6 ${filled ? 'text-yellow-400' : 'text-gray-300'}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21 12 17.27z" />
                </svg>
            ))}
        </div>
    );
};

export default StarRating;
