import React from "react";
import { useRegisterMutation } from "../../store/slice/studentSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { states } from "../../json/home/Register";
import { cities } from "../../json/home/Register";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      phone: "",
      tenthPercentage: "",
      tenthYearOfPassing: "",
      twelfthPercentage: "",
      twelfthYearOfPassing: "",
      graduationPercentage: "",
      graduationYearOfPassing: "",
      lookingFor: "",
      state: "",
      city: "",
    },
    onSubmit: async (values) => {
      const response = await register(values);
      if(response?.data?.success){
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate('/login');
        }, 1000);
      }else{
        toast.error(response?.error?.data?.message || "Fill form Correctly");
      }
    },
  });

  return (
    <div>
      <ToastContainer />
      <form
        onSubmit={formik.handleSubmit}
        className="max-w-3xl mx-auto p-6 shadow-lg border-2 border-rose-600 m-10  "
      >
        <div className="mx-auto p-auto mb-5 ">
          <h3 className="lg:text-3xl font-bold text-blue-700 text-center underline ">
            Register Yourself Here
          </h3>
        </div>
        <div className="grid lg:grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="fullName"
              className="block text-sm font-medium text-gray-700"
            >
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.fullName && formik.errors.fullName
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.fullName && formik.errors.fullName && (
              <div className="text-red-500">{formik.errors.fullName}</div>
            )}
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500">{formik.errors.email}</div>
            )}
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.password && formik.errors.password
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500">{formik.errors.password}</div>
            )}
          </div>

          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone
            </label>
            <input
              type="number"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.phone && formik.errors.phone
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500">{formik.errors.phone}</div>
            )}
          </div>

          <div>
            <label
              htmlFor="tenthPercentage"
              className="block text-sm font-medium text-gray-700"
            >
              10th Percentage
            </label>
            <input
              type="number"
              name="tenthPercentage"
              value={formik.values.tenthPercentage}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.tenthPercentage && formik.errors.tenthPercentage
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.tenthPercentage &&
              formik.errors.tenthPercentage && (
                <div className="text-red-500">
                  {formik.errors.tenthPercentage}
                </div>
              )}
          </div>

          <div>
            <label
              htmlFor="tenthYearOfPassing"
              className="block text-sm font-medium text-gray-700"
            >
              10th Year of Passing
            </label>
            <input
              type="number"
              name="tenthYearOfPassing"
              value={formik.values.tenthYearOfPassing}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.tenthYearOfPassing &&
                  formik.errors.tenthYearOfPassing
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.tenthYearOfPassing &&
              formik.errors.tenthYearOfPassing && (
                <div className="text-red-500">
                  {formik.errors.tenthYearOfPassing}
                </div>
              )}
          </div>

          <div>
            <label
              htmlFor="twelfthPercentage"
              className="block text-sm font-medium text-gray-700"
            >
              12th Percentage
            </label>
            <input
              type="number"
              name="twelfthPercentage"
              value={formik.values.twelfthPercentage}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.twelfthPercentage &&
                  formik.errors.twelfthPercentage
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.twelfthPercentage &&
              formik.errors.twelfthPercentage && (
                <div className="text-red-500">
                  {formik.errors.twelfthPercentage}
                </div>
              )}
          </div>

          <div>
            <label
              htmlFor="twelfthYearOfPassing"
              className="block text-sm font-medium text-gray-700"
            >
              12th Year of Passing
            </label>
            <input
              type="number"
              name="twelfthYearOfPassing"
              value={formik.values.twelfthYearOfPassing}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.twelfthYearOfPassing &&
                  formik.errors.twelfthYearOfPassing
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.twelfthYearOfPassing &&
              formik.errors.twelfthYearOfPassing && (
                <div className="text-red-500">
                  {formik.errors.twelfthYearOfPassing}
                </div>
              )}
          </div>

          <div>
            <label
              htmlFor="graduationPercentage"
              className="block text-sm font-medium text-gray-700"
            >
              Graduation Percentage
            </label>
            <input
              type="number"
              name="graduationPercentage"
              value={formik.values.graduationPercentage}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.graduationPercentage &&
                  formik.errors.graduationPercentage
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.graduationPercentage &&
              formik.errors.graduationPercentage && (
                <div className="text-red-500">
                  {formik.errors.graduationPercentage}
                </div>
              )}
          </div>

          <div>
            <label
              htmlFor="graduationYearOfPassing"
              className="block text-sm font-medium text-gray-700"
            >
              Graduation Year of Passing
            </label>
            <input
              type="number"
              name="graduationYearOfPassing"
              value={formik.values.graduationYearOfPassing}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.graduationYearOfPassing &&
                  formik.errors.graduationYearOfPassing
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.graduationYearOfPassing &&
              formik.errors.graduationYearOfPassing && (
                <div className="text-red-500">
                  {formik.errors.graduationYearOfPassing}
                </div>
              )}
          </div>

          <div>
            <label
              htmlFor="lookingFor"
              className="block text-sm font-medium text-gray-700"
            >
              Looking For
            </label>
            <input
              type="text"
              name="lookingFor"
              value={formik.values.lookingFor}
              onChange={formik.handleChange}
              className={`mt-1 block w-full border p-2 ${formik.touched.lookingFor && formik.errors.lookingFor
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded-md`}
            />
            {formik.touched.lookingFor && formik.errors.lookingFor && (
              <div className="text-red-500">{formik.errors.lookingFor}</div>
            )}
          </div>

          <div>
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700"
            >
              State
            </label>
            <select
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            >
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              City
            </label>
            <select
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            >
              {formik.values.state &&
                cities[formik.values.state]?.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="mt-6 bg-blue-600 text-white py-2 px-4 rounded-md"
          disabled={isLoading}
        >
          {isLoading ? "Registering..." : "Register"}
        </button>
      </form>
    </div>
  );
};

export default Register;
