import React from "react";
import { useFetchAllSpeciliazationByCourseQuery } from "../../store/slice/CollegeSlice";
import { Link, useParams } from "react-router-dom";

const SpecializationsByCourse = () => {
  const {id} = useParams();
  const { data: Specialization } = useFetchAllSpeciliazationByCourseQuery(id);


  return (
    <div>
      <div className="m-10">
   

        {Specialization?.specializations?.length > 0 &&
          Specialization?.specializations?.map((item, index) => (
            <div className="h-auto w-[250px] border-2 border-black" key={index}>
              <img
                className="w-[250px] h-[200px]"
                src="https://instructor-academy.onlinecoursehost.com/content/images/size/w2000/2023/05/How-to-Create-an-Online-Course-For-Free--Complete-Guide--6.jpg"
              />
              <h3 className="text-center font-semibold italic">
                Name: {item.specializationName}
              </h3>
              <h3 className="text-center font-semibold italic">
                Course Name: {item?.courseId?.courseName}
              </h3>
              <div className="flex justify items-center mb-2  ">
                <Link
                  to={`/specialization-view/${item?._id}`}
                  className="mx-auto bg-blue-600 p-3 rounded-lg  text-white "
                >
                  Read more
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SpecializationsByCourse;
