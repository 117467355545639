import React, { useState } from 'react';
import AllArticle from './AllArticle';
import { useFetchArticlesByCategoryQuery } from '../../store/slice/ArticleSlice';
import { useFetchAllCollegesQuery } from '../../store/slice/CollegeSlice';
import Colleges from '../../pages/Colleges';
import { useParams } from 'react-router-dom';

const UgPgComponent = () => {
    const [activeTab, setActiveTab] = useState('courses');
    const params = useParams();
    const { data } = useFetchArticlesByCategoryQuery(params?.id);
    const Response = useFetchAllCollegesQuery(params?.id);
    const articles = data?.data;
    const colleges = Response?.data?.data;

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    return (
        <div className='py-10'>
            {/* <div className='text-center'>
                <h2 className='text-center font-bold text-3xl border-b-4 border-red-600 inline'>Courses and Colleges</h2>
            </div> */}
            <div className='flex lg:gap-10 gap-6 text-sm font-semibold items-center justify-center py-2 lg:text-xl'>
                <button
                    className={`cursor-pointer ${activeTab === 'courses' ? 'border-b-2 border-red-700' : ''}`}
                    onClick={() => handleTabChange('courses')}
                >
                   {params?.name}
                </button>
                <button
                    className={`cursor-pointer ${activeTab === 'colleges' ? 'border-b-2 border-red-700' : ''}`}
                    onClick={() => handleTabChange('colleges')}
                >
                    All Colleges
                </button>
            </div>
            <div className='px-4'>
                {activeTab === 'courses' && (
                    <AllArticle
                        heading="Undergraduate Courses"
                        data={articles}
                    />
                )}
                {activeTab === 'colleges' && (
                    <Colleges
                    data={colleges} />
                )}
            </div>
        </div>
    );
};




export default UgPgComponent;
