import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainRoute } from "./json/MainRoute";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PrivateRoute from "./Private";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setAuth } from "./store/reducer/auth.slice";

function App() {



  //useEffect is added by me and dispatch as well
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route Component={PrivateRoute}>
          {MainRoute?.map((item, index) => (
            <Route path={item?.Url} Component={item?.Component} key={index} />
          ))}
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
