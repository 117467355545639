import React, { useEffect, useState } from "react";
import {
  useCreateReviewMutation,
  useFetchAllForSearchQuery,
} from "../../store/slice/CollegeSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CreateReview = () => {
  const navigate = useNavigate();
  const [facultyRating, setFacultyRating] = useState(0);
  const [placementRating, setPlacementRating] = useState(0);
  const [campusRating, setCampusRating] = useState(0);
  const [studentLifeRating, setStudentLifeRating] = useState(0);
  const [generalRating, setGeneralRating] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [createReview, { isLoading }] = useCreateReviewMutation();
  const [selectedCollegeId, setSelectedCollegeId] = useState("");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  const { data: colleges = [], isLoading: isFetchingColleges } = useFetchAllForSearchQuery(searchQuery);


  useEffect(() => {
    if (!isAuthenticated && user === null) {
      navigate('/login')
      toast.warning('Please Login First');
    }
  }, [isAuthenticated,user,navigate])


  useEffect(() => {
    if (selectedCollege && Array.isArray(colleges)) {
      const college = colleges.find(
        (college) => college._id === selectedCollege._id
      );
      if (college) {
        setSelectedCollegeId(college._id);
      } else {
        setSelectedCollegeId("");
      }
    }
  }, [selectedCollege, colleges]);

  const notifySuccess = () => {
    toast.success("Review Created Successfully");
  };

  const notifyFailure = () => {
    toast.error("Please fill all the Fields");
  };

  const calculateAverageRating = () => {
    const ratings = [
      facultyRating,
      placementRating,
      campusRating,
      studentLifeRating,
    ];
    const sum = ratings.reduce((a, b) => a + b, 0);
    return (ratings.length ? sum / ratings.length : 0).toFixed(1);
  };

  const handleSubmit = async () => {
    if (!selectedCollegeId) {
      alert("Please select a college");
      return;
    }
    try {
      const averageRating = calculateAverageRating();
      const data = {
        CollegeId: selectedCollegeId,
        Facultyrating: facultyRating,
        Placementrating: placementRating,
        Campusrating: campusRating,
        StudentLiferating: studentLifeRating,
        generalRating,
        averageRating,
      };

      await createReview(data).unwrap();
      notifySuccess();
      setFacultyRating(0);
      setPlacementRating(0);
      setCampusRating(0);
      setStudentLifeRating(0);
      setGeneralRating("");
      setSelectedCollege(null);
      setSelectedCollegeId(""); 
      setSearchQuery("");
      navigate('/allcolleges')
    } catch (error) {
      notifyFailure();
      console.error("Failed to create review:", error);
    }
  };

  return (
    <div className="bg-gray-100 py-12">
      <ToastContainer />
      <div className="mx-auto max-w-xl rounded-lg shadow-lg bg-white p-8 border-2 border-red-600">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Make Your Opinion Count!
          </h2>
          <p className="text-gray-700 mb-4">
            Your experience can guide thousands of students. Share your insights
            and help others find their path.
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-semibold text-gray-800 mb-2 text-center">
            Search College:
          </h3>
          <input
            type="text"
            className="p-4 border border-gray-300 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-400 transition duration-200 ease-in-out"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Type to search for colleges..."
            disabled={isFetchingColleges}
          />
        </div>
        {searchQuery && colleges.length > 0 && !selectedCollege && (
          <div className="mb-8">
            <ul className="space-y-2">
              {colleges.map((college) => (
                <li
                  key={college._id}
                  className={`p-4 border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 ${selectedCollege?._id === college._id ? "bg-yellow-100" : ""
                    }`}
                  onClick={() => {
                    setSelectedCollegeId(college._id);
                    setSearchQuery(college.name);

                  }}
                >
                  {college.name}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Interactive Rating Section */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold text-gray-800 mb-2 text-center">
            Rate Your Experience:
          </h3>
          <div className="space-y-4 grid grid-cols-2">
            {[
              {
                label: "Faculty",
                value: facultyRating,
                setValue: setFacultyRating,
              },
              {
                label: "Placement",
                value: placementRating,
                setValue: setPlacementRating,
              },
              {
                label: "Campus",
                value: campusRating,
                setValue: setCampusRating,
              },
              {
                label: "Academic ",
                value: studentLifeRating,
                setValue: setStudentLifeRating,
              },
            ].map(({ label, value, setValue }) => (
              <div key={label}>
                <label className="block text-center mb-2 font-semibold text-gray-800">
                  {label} Rating
                </label>
                <div className="flex items-center justify-center">
                  {[...Array(5)].map((_, index) => (
                    <span
                      key={index}
                      className={`text-4xl cursor-pointer transition duration-200 ease-in-out ${index < value ? "text-yellow-400" : "text-gray-300"
                        }`}
                      onClick={() => setValue(index + 1)}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* General Rating Section */}
        <div className="mb-8">
          <label className="block text-center mb-2 font-semibold text-gray-800">
            General Rating
          </label>
          <textarea minLength={100}
            className="p-4 border border-gray-300 rounded-lg w-full shadow-sm pr-20 focus:outline-none focus:ring-2 focus:ring-yellow-400 transition duration-200 ease-in-out resize-none"
            rows="3"
            value={generalRating}
            onChange={(e) => setGeneralRating(e.target.value)}
            placeholder="Describe your overall experience..."
          />
        </div>

        <button
          className="h-12 bg-red-600 text-white font-bold rounded-lg shadow-md hover:bg-red-500 transition duration-200 ease-in-out w-full"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit Review"}
        </button>
      </div>
    </div>
  );
};

export default CreateReview;