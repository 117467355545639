export const PrimaryMenu = [
    {
        Name: 'UG',
        Url: '/article/colleges/courses/UG Courses/6595578ebed688263641db9d',
        // SubMenu: [
        //     {
        //         Name: 'Engineering Courses',
        //         Url: '/engineering-colleges',
        //         SubMenu: [
        //             { Name: 'Computer Engineering', Url: '/' },
        //             { Name: 'Mechanical Engineering', Url: '/' },
        //             { Name: 'Civil Engineering', Url: '/' },
        //             { Name: 'Electrical Engineering', Url: '/' },
        //             { Name: 'Electronics Engineering', Url: '/' },
        //             { Name: 'Chemical Engineering', Url: '/' },
        //             { Name: 'Aerospace Engineering', Url: '/' },
        //             { Name: 'Biomedical Engineering', Url: '/' },
        //             { Name: 'Environmental Engineering', Url: '/' },
        //             { Name: 'Software Engineering', Url: '/' },
        //             { Name: 'Petroleum Engineering', Url: '/' },
        //             { Name: 'Agricultural Engineering', Url: '/' },
        //             { Name: 'Industrial Engineering', Url: '/' },
        //             { Name: 'Marine Engineering', Url: '/' },
        //             { Name: 'Metallurgical Engineering', Url: '/' }
        //         ]
        //     },
        //     {
        //         Name: 'Medical Courses',
        //         Url: '/medical-colleges',
        //         SubMenu: [
        //             { Name: 'MBBS', Url: '/' },
        //             { Name: 'BDS', Url: '/' },
        //             { Name: 'BAMS', Url: '/' },
        //             { Name: 'BHMS', Url: '/' },
        //             { Name: 'BUMS', Url: '/' },
        //             { Name: 'BPT', Url: '/' },
        //             { Name: 'B.Sc Nursing', Url: '/' },
        //             { Name: 'B.V.Sc', Url: '/' },
        //             { Name: 'B.Optom', Url: '/' },
        //             { Name: 'B.Pharma', Url: '/' },
        //             { Name: 'BMLT', Url: '/' },
        //             { Name: 'BOT', Url: '/' },
        //             { Name: 'BASLP', Url: '/' },
        //             { Name: 'B.Sc Radiology', Url: '/' },
        //             { Name: 'B.Sc Cardiology', Url: '/' }
        //         ]
        //     },
        //     {
        //         Name: 'Law Courses',
        //         Url: '/law-colleges',
        //         SubMenu: [
        //             { Name: 'LLB', Url: '/' },
        //             { Name: 'BA LLB', Url: '/' },
        //             { Name: 'BBA LLB', Url: '/' },
        //             { Name: 'B.Sc LLB', Url: '/' },
        //             { Name: 'B.Com LLB', Url: '/' }
        //         ]
                
        //     }
        // ]
    },
    {
        Name: 'PG',
        Url: '/article/colleges/courses/PG Courses/65a614fc20541e80817e597d',
        // SubMenu: [
        //     {
        //         Name: 'Engineering Colleges',
        //         Url: '/engineering-colleges',
        //         SubMenu: [
        //             { Name: 'M.Tech Computer Engineering', Url: '/' },
        //             { Name: 'M.Tech Mechanical Engineering', Url: '/' },
        //             { Name: 'M.Tech Civil Engineering', Url: '/' },
        //             { Name: 'M.Tech Electrical Engineering', Url: '/' },
        //             { Name: 'M.Tech Electronics Engineering', Url: '/' },
        //             { Name: 'M.Tech Chemical Engineering', Url: '/' },
        //             { Name: 'M.Tech Aerospace Engineering', Url: '/' },
        //             { Name: 'M.Tech Biomedical Engineering', Url: '/' },
        //             { Name: 'M.Tech Environmental Engineering', Url: '/' },
        //             { Name: 'M.Tech Software Engineering', Url: '/' },
        //             { Name: 'M.Tech Petroleum Engineering', Url: '/' },
        //             { Name: 'M.Tech Agricultural Engineering', Url: '/' },
        //             { Name: 'M.Tech Industrial Engineering', Url: '/' },
        //             { Name: 'M.Tech Marine Engineering', Url: '/' },
        //             { Name: 'M.Tech Metallurgical Engineering', Url: '/' }
        //         ]
                
        //     },
        //     {
        //         Name: 'Medical Colleges',
        //         Url: '/medical-colleges',
        //         SubMenu: [
        //             { Name: 'MD', Url: '/' },
        //             { Name: 'MS', Url: '/' },
        //             { Name: 'M.Sc Nursing', Url: '/' },
        //             { Name: 'M.Pharma', Url: '/' },
        //             { Name: 'MPT', Url: '/' },
        //             { Name: 'M.V.Sc', Url: '/' },
        //             { Name: 'M.Optom', Url: '/' },
        //             { Name: 'M.Sc Radiology', Url: '/' },
        //             { Name: 'M.Sc Cardiology', Url: '/' },
        //             { Name: 'MS Orthopedics', Url: '/' },
        //             { Name: 'MS General Surgery', Url: '/' },
        //             { Name: 'MD Pediatrics', Url: '/' },
        //             { Name: 'MD Dermatology', Url: '/' },
        //             { Name: 'MD Psychiatry', Url: '/' },
        //             { Name: 'MD Radiology', Url: '/' }
        //         ]
                
        //     },
        //     {
        //         Name: 'Law Colleges',
        //         Url: '/law-colleges',
        //         SubMenu: [
        //             { Name: 'LLM', Url: '/' },
        //             { Name: 'Diploma in Law', Url: '/' },
        //             { Name: 'Ph.D. in Law', Url: '/' },
        //             { Name: 'Corporate Law', Url: '/' },
        //             { Name: 'International Law', Url: '/' }
        //         ]
                
        //     }
        // ]
    },
    { Name: 'Colleges', Url: '/allcolleges' },
    { Name: 'Certification/Diploma', Url: '/article/category/65a6151720541e80817e5991' },
    { Name: 'Internships', Url: '/comingSoon' },
    { Name: 'Scholarships', Url: '/article/category/65e091db7bf21f4035800225' },
  
];
