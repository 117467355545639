import React from 'react'
import logo from '../../assets/logo/whitelogo.png'
import { IoMdMail } from 'react-icons/io'
import { FaFacebook, FaInstagram, FaLinkedin, FaPhoneAlt } from 'react-icons/fa'
import { BsTwitterX } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='bg-gray-800  text-white  px-6 '>
      <div className='grid lg:grid-cols-2 grid-cols-1 py-6'>
        <div className=''>
          <img src={logo} alt=""  className='w-[200px]' />
          <h4 className='text-sm'>Shikshapedia is a comprehensive platform designed to empower students in their educational journey. Our platform offers a user-friendly interface, providing students with detailed insights, enabling discovery, and facilitating in-depth research on colleges, courses, and exams aligned with their interests.</h4>
        </div>
        <div className='lg:text-center text-left lg:mt-0 mt-10'>
        <h4 className='text-lg font-bold underline'>Contact Us</h4>
        <div className=' lg:w-[250px] my-4  text-left m-auto'>
          <p><Link to='mailto:info@Shikshapedia.com'><IoMdMail className='inline mx-2 my-4'  size={18}/>info@Shikshapedia.com</Link></p>
          <p><Link to='tel:+919654660404'><FaPhoneAlt className='inline mx-2 my-4'  size={18}/>+91 9654660404</Link></p>
          <p className='flex gap-6 mx-4 text-2xl mt-4'>
          <Link to='https://www.facebook.com/ShikshaPediaEDU'><FaFacebook /></Link>
          <Link to='https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fshikshapediaedu%2F&is_from_rle'><FaInstagram /></Link>
          <Link to='https://x.com/i/flow/login?redirect_after_login=%2FShikshaPediaEDU'><BsTwitterX /></Link>
          <Link to='https://www.linkedin.com/company/shikshapediaedu/'><FaLinkedin /></Link>
          </p>
        </div>
        </div>
      </div>
      <div className='py-4'>
        <p className='text-center text-sm'>© 2024 Copyright: Shikshapedia.com</p>
      </div>
    </div>
  )
}

export default Footer
