import React from 'react'
import { useParams } from 'react-router-dom'
import PageSlider from '../assets/PageSlider.png';
import AllArticle from '../components/article/AllArticle';
import { useFetchArticlesByCategoryQuery } from '../store/slice/ArticleSlice';

const ArticlesByCategory = () => {
  window.scroll(0,0);
  const {id} = useParams();
  const {data,isLoading}= useFetchArticlesByCategoryQuery(id);
  const Articles = data?.data;

  return (
    <div>
      <div>
        <img src={PageSlider} className='w-[100vw]' alt="" />
      </div>
      <AllArticle data={Articles} isLoading={isLoading} />
    </div>
  )
}

export default ArticlesByCategory
