import React, { useState } from "react";
import { useFetchAllSpeciliazationByCourseQuery, useFetchCourseDataQuery } from "../../store/slice/CollegeSlice";
import { useParams } from "react-router-dom";


export const CourseView = () => {
  const { id } = useParams();
  const { data: CourseData } = useFetchCourseDataQuery(id);
  const { data: Specialization } = useFetchAllSpeciliazationByCourseQuery(id);
  const [activeTab, setActiveTab] = useState('Basic Info');
  const storage = process.env.REACT_APP_S3_BUCKET;
  const CourseImage = storage + CourseData?.Course?.collegeId?.coverimage;


  const renderTabContent = () => {
    if (activeTab === 'Basic Info') {
      return (
        <div>
          <div dangerouslySetInnerHTML={{ __html: CourseData?.Course?.content }} />
        </div>
      );
    } else {
      const specialization = Specialization?.specializations.find(
        (spec) => spec.specializationName === activeTab
      );
  
      return (
        <div>
          {specialization ? (
            <div>
              <div dangerouslySetInnerHTML={{ __html: specialization.content }} />
            </div>
          ) : (
            <div>Content not available</div>
          )}
        </div>
      );
    }
  };
  

  if (!CourseData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Background Section */}
      <div
        style={{
          backgroundImage: `url(${CourseImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="min-h-[400px] bg-cover bg-center flex justify-center items-center"
      >
        <h1 className="text-white text-4xl font-bold bg-black bg-opacity-50 px-6 py-3 rounded-lg text-center">
          {CourseData?.Course?.courseName}
        </h1>
      </div>

      {/* Course Info Section */}
      <div className="max-w-6xl -mt-16 mx-auto bg-white shadow-lg rounded-lg">
        <div className="p-6">
          <div className="flex flex-col sm:flex-row items-center">
            <div className="text-center sm:text-left">
              <h2 className="text-3xl font-bold text-gray-800">
                {CourseData?.Course?.courseName}
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* Tab Navigation */}
      <div className="max-w-6xl mx-auto my-10">
        <div className="flex flex-col sm:flex-row space-x-3">
          <button
            onClick={() => setActiveTab('Basic Info')}
            className={`px-4 py-2 rounded ${activeTab === 'Basic Info' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
          >
            Course Info
          </button>
          {Specialization?.specializations.map(spec => (
            <button
              key={spec.id} 
              onClick={() => setActiveTab(spec.specializationName)}
              className={`px-4 mt-3 lg:mt-0 py-2 rounded ${activeTab === spec.specializationName ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
            >
              {spec.specializationName}
            </button>
          ))}
        </div>

        {/* Tab Content Section */}
        <div className="border border-gray-300 rounded mt-10 p-6 bg-white shadow-md">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default CourseView;
