import React from 'react'
import { TextConcat } from '../../utils/TextConcat';
import { Link } from 'react-router-dom';
import Loader from '../../utils/Loader';
const storage = process.env.REACT_APP_S3_BUCKET;

const AllArticle = ({data,isLoading}) => {


    return (
        <div className=''>
            {/* <div className='text-center'>
                <h2 className='text-center font-bold text-3xl border-b-4 border-red-600 inline'>{data[0]?.category?.name }</h2>
            </div> */}
            <div>
                {isLoading && <Loader/>}
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4  px-4'>
                {data?.length > 0 && data?.map(item=>(
                    <div className='border border-gray-200 shadow rounded' key={item?._id}>
                        <div className='flex gap-2 px-4 pt-2'>
                        <img src={storage+item?.thumbnail} alt="" className='w-[150px] max-h-[100px] rounded my-auto' />
                        <div>
                        <Link to={`/article/${item?.slug}`} className='hover:underline text-lg font-bold'>{item?.title}</Link>
                        </div>
                        </div>
                        <div>
                            <p className='text-sm px-4 my-2'>{TextConcat(item?.shortDesc,200)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AllArticle
