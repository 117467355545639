import { FaMoneyBill, FaUniversity, FaUserNurse } from "react-icons/fa";
import { FaComputer, FaUserDoctor } from "react-icons/fa6";
import { GoLaw } from "react-icons/go";
import { IoBookSharp } from "react-icons/io5";

export const EntranceExamsTabs = [
    {
        Name:'JEE',
        Url:'/article/jee',
        Icon:<FaUniversity />
    },
    {
        Name:'BITSAT',
        Url:'/article/bitsat',
        Icon:<FaMoneyBill />
    },
    {
        Name:'VITEE',
        Url:'/article/viteee',
        Icon:<FaUserNurse/>
    },
    {
        Name:'MHT',
        Url:'/article/mht-cet',
        Icon:<IoBookSharp/>
    },
    {
        Name:'NEET',
        Url:'/article/neet',
        Icon:<FaUserDoctor />
    },
    {
        Name:'UPSC',
        Url:'/',
        Icon:<FaComputer/>
    },
    {
        Name:'NDA',
        Url:'/article/nda',
        Icon:<FaUniversity />
    },
    {
        Name:'NEET',
        Url:'/article/neet',
        Icon:<FaUserDoctor />
    },
    {
        Name:'CMAT',
        Url:'/article/cmat',
        Icon:<FaUserDoctor />   
    },
    {
        Name:'NATA',
        Url:'/article/nata',
        Icon:<FaUniversity />
    },
    {
        Name:'CLAT',
        Url:'/article/clat',
        Icon:<GoLaw/>
    },
    {
        Name:'LSAT',
        Url:'/article/lsat',
        Icon:<FaUniversity />
    }, {
        Name:'NIFT',
        Url:'/article/nift',
        Icon:<FaUniversity />
    },

    {
        Name:'NCHM',
        Url:'/article/nchm',
        Icon:<FaUniversity />
    },
    {
        Name:'UGAT',
        Url:'/article/ugat',
        Icon:<FaUniversity />
    },   
    {
        Name:'CUET',
        Url:'/article/cuet-ug-2024-review-these-useful-techniques-for-gd-and-interviews-in-preparation-for-college-admissions',
        Icon:<FaUniversity />
    },  
    {
        Name:'IIMC',
        Url:'/article/iimc',
        Icon:<FaUniversity />
    },  
    {
        Name:'FTII JET',
        Url:'/article/ftii-entrance-exam',
        Icon:<FaUniversity />
    },  

]