import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { removeAuth, setAuth } from './store/reducer/auth.slice';
import { useRefreshQuery } from './store/slice/studentSlice';

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch } = useRefreshQuery();

  useEffect(() => {
    refetch();
    
    if (!isLoading) {
      if (!isError && data) {
        dispatch(setAuth(data.user));
      } else {
        dispatch(removeAuth());
      }
    } // eslint-disable-next-line
  }, [data, navigate]);

  if(isLoading){
    return "Loading...";
  }

  return (
    <section>
      <Outlet />
    </section>
  );
};

export default PrivateRoute;
