import { configureStore } from '@reduxjs/toolkit'
import { articleApiReducer, articleApiSlice } from './slice/ArticleSlice'
import { collegeApiReducer, collegeApiSlice } from './slice/CollegeSlice'
import { studentApiReducer, studentApiSlice } from './slice/studentSlice'
import authReducer from './reducer/auth.slice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        articlesApi: articleApiReducer,
        collegeApi: collegeApiReducer,
        studentApi: studentApiReducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            articleApiSlice.middleware,
            collegeApiSlice.middleware,
            studentApiSlice.middleware
        ),
})