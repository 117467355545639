import React from 'react';
import { useFetchArticlesByCategoryQuery } from '../../store/slice/ArticleSlice';
import { TextConcat } from '../../utils/TextConcat';
import { Link } from 'react-router-dom';

const storage = process.env.REACT_APP_S3_BUCKET;
const tabId = '65e091db7bf21f4035800225';

const TrendingNews = () => {
  const { data, isLoading } = useFetchArticlesByCategoryQuery(tabId);
  const articles = data?.data;

  return (
    <div className="px-4">
      <div className='text-center py-6  bg-[#DDFFDD] rounded-lg mb-10'>
                <h2 className='text-center font-bold text-3xl border-b-4 border-red-600 inline p-2 my-2'>Trending News</h2>
            </div>

      {articles?.length > 0 && (
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
          {articles.slice(0, 8).map((article, index) => (
            <Link to={`/article/${article.slug}`} key={index} className="block">
              <div className="bg-white rounded-lg overflow-hidden shadow-lg">
                <img
                  src={`${storage}${article.thumbnail}`}
                  alt={article.title}
                  className="w-full h-auto object-cover"
                  style={{ height: '120px' }}
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold text-gray-800 mb-2">{TextConcat(article.title, 50)}</h3>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}

      {isLoading && <p className="text-center">Loading...</p>}
      <div className='text-center mt-4'>
                {articles?.length > 0 && (
                    <Link to="/article/category/65e091db7bf21f4035800225">
                        <button className='bg-red-600 px-4 py-2 text-white font-bold rounded hover:bg-gray-400'>View More</button>
                    </Link>
                )}
            </div>
    </div>

  );
};

export default TrendingNews;
