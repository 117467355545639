import React, { useEffect, useState } from "react";
import {
  useFetchCourseDataQuery,
  useFetchSpecilializationByIdQuery,
} from "../../store/slice/CollegeSlice";
import { useParams } from "react-router-dom";
import SpecializationsByCourse from "./SpecializationsByCourse";

export const SpecializationView = () => {
  const { id } = useParams();
  const { data: SpecData } = useFetchSpecilializationByIdQuery(id);
  console.log("jj");
  console.log(SpecData?.Specialization);

  const renderTabContent = () => {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: SpecData?.Specialization?.content,
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(https://img.freepik.com/free-photo/standard-quality-control-collage-concept_23-2149595840.jpg?w=826&t=st=1729330148~exp=1729330748~hmac=04a090e897a4fca6d65374a129fe3b72b77f0fb26ed2624eb865955b71f73316)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="min-h-[400px] bg-cover bg-center flex justify-center items-center"
      >
        <h1 className="text-white text-4xl font-bold bg-black bg-opacity-50 px-6 py-3 rounded-lg text-center">
          {console.log("hsjnxkj ", SpecData?.Specialization.specializationName)}
          {SpecData?.Specialization.specializationName}
        </h1>
      </div>

      {/* Course Info Section */}
      <div className="max-w-6xl -mt-16 mx-auto bg-white shadow-lg rounded-lg">
        <div className="p-6">
          <div className="flex flex-col sm:flex-row items-center">
            <div className="text-center sm:text-left">
              <h2 className="text-3xl font-bold text-gray-800">
                {SpecData?.Specialization?.specializationName}
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* Tab Navigation */}
      <div className="max-w-6xl mx-auto my-10">
        <div className=" flex-col space-x-3">
          <button className={`px-4 py-2 rounded bg-blue-500 text-white `}>
            Basic Info
          </button>
          {/* <button
            onClick={() => setActiveTab('Specialization')}
            className={`px-4 py-2 rounded ${activeTab === 'Specialization' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
          >
            Specialization
          </button> */}
        </div>

        {/* Tab Content Section */}
        <div className="border border-gray-300 rounded mt-10 p-6 bg-white shadow-md">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default SpecializationView;
