import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const collegeApiSlice = createApi({
  reducerPath: "collegeApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),



  endpoints: (builder) => ({
    
    //Api for Colleges 


    fetchAllColleges: builder.query({
      query: (list) => `/college/all/${list}`,
    }),
    fetchAllCollegesbyName: builder.query({
      query: () => `/college/all`,
    }),
    fetchAllForSearch: builder.query({
      query: (query) => `/college/search?search=${query}`,
    }),

    fetchCollegesBySlug: builder.query({
      query: (slug) => `/college/slug/${slug}`,
    }),
   
  //API for CollegePost

    fetchCollegesPostAll: builder.query({
      query: (collegeName) => `/college/post/byCollege/${collegeName}`,
    }),

    //API for College Reviews

    createReview: builder.mutation({
      query: (reviewData) => ({
        url: `/review/create`,
        method: "POST",
        body: reviewData,
      }),
    }),
    fetchAllReviews: builder.query({
      query: (collegeId) => `/review/college/get/${collegeId}`,
    }),
    fetchAllTheReviews: builder.query({
      query: () => `/review/all`,
    }),


    //API For Courses

    fetchAllCourses: builder.query({
      query: (collegeId) => `college/course/CollegeId/${collegeId}`,
    }),
    fetchAllSpeciliazationByCourse: builder.query({
      query: (courseId) => `college/specialization/course/${courseId}`,
    }),
    fetchCollegesByLocation: builder.query({
      query: (param) => `/college/compare/${param.country}/${param.state}`,
    }),

    fetchAllCoursesList: builder.query({
      query: () => `/college/course/all`,
    }),
    fetchCourseData: builder.query({
      query: (id) => `/college/course/${id}`,
    }),
    fetchCourseBySlug: builder.query({
      query: (slug) => `/college/course/slug/${slug}`,
    }),
    fetchSpecilializationById:builder.query({
      query:(id)=> `/college/specialization/${id}`,
    })
  }),

});

export const {
  useFetchAllCollegesQuery,
  useFetchAllCollegesbyNameQuery,
  useFetchCollegesBySlugQuery,
  useFetchAllForSearchQuery,
  useFetchCollegesPostAllQuery,
  useCreateReviewMutation,
  useFetchAllReviewsQuery,
  useFetchAllCoursesQuery,
  useFetchAllSpeciliazationByCourseQuery,
  useFetchAllTheReviewsQuery,
  useFetchCollegesByLocationQuery,
  useFetchAllCoursesListQuery,
  useFetchCourseDataQuery,
  useFetchCourseBySlugQuery,
  useFetchSpecilializationByIdQuery
} = collegeApiSlice;

export const collegeApiReducer = collegeApiSlice.reducer;
