import React, { useEffect, useState } from 'react'
import AllColleges from '../components/college/AllColleges'
import { useFetchAllCollegesQuery } from '../store/slice/CollegeSlice';
import ReactPaginate from 'react-paginate';

const Colleges = () => {
    const [namefilter, setNameFilter] = useState('');
    const [locationfilter, setlocationFilter] = useState('');
    const [coursefilter, setCourseFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paramlist, setParamlist] = useState(`?search=${namefilter}&location=${locationfilter}&courses=${coursefilter}&page=${currentPage}`);;

    const { data, refetch } = useFetchAllCollegesQuery(paramlist);

    useEffect(() => {
        setParamlist(`?search=${namefilter}&location=${locationfilter}&courses=${coursefilter}&page=${currentPage}`);
        window.scroll(0, 0);
        refetch(); //eslint-disable-next-line
    }, [namefilter, locationfilter, coursefilter, currentPage]);


    return (
        <div className='py-10'>
            <div>
                <h2 className='text-center font-bold text-3xl mb-4'>Search Colleges</h2>
            </div>
            <div className='flex lg:gap-6 gap-4 max-w-5xl m-auto px-2 mx-auto'>
                <input type="text" className='border border-gray-400 outline-none px-2 py-1 w-full h-10 rounded' placeholder='By name' onChange={(e) => setNameFilter(e.target.value)} />
                <input type="text" className='border border-gray-400 outline-none px-2 py-1 w-full h-10 rounded' placeholder='By location' onChange={(e) => setlocationFilter(e.target.value)} />
                <input type="text" className='border border-gray-400 outline-none px-2 py-1 w-full h-10 rounded' placeholder='By courses' onChange={(e) => setCourseFilter(e.target.value)} />
            </div>
            <AllColleges data={data?.data} />
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={(e) => {
                    setCurrentPage(e?.selected + 1)
                }}
                className='flex flex-wrap pagination gap-2 justify-center'
                pageRangeDisplayed={1}
                pageCount={data?.pagination?.totalPages}
                previousLabel="< previous"
            />
        </div>
    )
}

export default Colleges
