import React from 'react';
import { useFetchArticlesByCategoryQuery } from '../../store/slice/ArticleSlice';
import { TextConcat } from '../../utils/TextConcat';
import { Link } from 'react-router-dom';

const tabId = "65e0920e7bf21f403580022c";

const Scholarship = () => {
  const { data, isLoading } = useFetchArticlesByCategoryQuery(tabId);
  const articles = data?.data;

  return (
    <div className="container mx-auto mt-10 px-4">
      <div className='text-center py-4 bg-[#DDFFDD] rounded-lg'>
        <h2 className='text-center font-bold text-3xl border-b-4 border-red-600 inline '>Scholarship</h2>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2  gap-6 mt-8">
        {isLoading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="animate-pulse h-48 w-full bg-gray-200"></div>
              <div className="p-4">
                <div className="animate-pulse h-4 w-3/4 mb-2 bg-gray-200"></div>
                <div className="animate-pulse h-4 w-1/2 bg-gray-200"></div>
              </div>
            </div>
          ))
        ) : (
          articles?.slice(0, 8).map((article, index) => (
            <Link key={index} to={`/article/${article.slug}`} className="group">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition duration-300">
                <div className="relative">
                  <img
                    src={`${process.env.REACT_APP_S3_BUCKET}${article.thumbnail}`}
                    alt={article.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <h3 className="text-white text-xl font-bold text-center">{TextConcat(article.title, 50)}</h3>
                  </div>
                </div>
                <div className="p-4">
                  <p className="text-sm text-gray-600">{TextConcat(article.title, 60)}</p>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
      <div className='text-center mt-4'>
        {articles?.length > 0 && (
          <Link to="/article/category/65e0920e7bf21f403580022c">
            <button className='bg-red-600 px-4 py-2 text-white font-bold rounded hover:bg-gray-400'>View More</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Scholarship;
